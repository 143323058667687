document.addEventListener('DOMContentLoaded', () => {
  const passwordIcons= document.querySelectorAll('.password-icon-js')
  if (passwordIcons) {
    passwordIcons.forEach(passwordIcon => {
      passwordIcon.addEventListener('click', () => {
        let label = passwordIcon.closest('label');
        let input = label.querySelector('input');
        input.type = input.type === 'password' ? 'text' : 'password';
        passwordIcon.classList.toggle('password-icon-js--active')
      })
    })
  }
})
