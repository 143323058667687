document.addEventListener('DOMContentLoaded', () => {
  let tabsRadio = document.querySelectorAll('input[name="nav"]');
  let inputs = document.querySelectorAll('input')
  if (tabsRadio) {
    tabsRadio.forEach(input => {
      input.addEventListener('change', () => {
        inputs.forEach(inputEl => {
          if (inputEl.classList.contains('is-invalid')) {
            inputEl.classList.remove('is-invalid')
            let error = inputEl.parentElement.querySelector('.input__error')
            error.textContent = ''
          }
          inputEl.value = ''
        })
      })
    })
  }
})
