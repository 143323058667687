import InputMask from "inputmask";

document.addEventListener('DOMContentLoaded', () => {
  let inputsPhones = document.querySelectorAll('input[name="phone"]');
  if (inputsPhones) {
    inputsPhones.forEach(phoneInput => {
      InputMask({mask:'+7 999-999-99-99'}).mask(phoneInput)
    })
  }
})
